@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400..700;1,400..700&family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --dark-gradient: linear-gradient(0deg, #FE621D, #FEA01D);
  --light-gradient: linear-gradient(0deg, #962734, #FC4A60);
}

/* h1, h2, h3, h4{
  font-family: 'NeueAachenPro';
} */
/* @font-face {
  font-family: 'NeueAachenPro';
  src: url(/public/fonts/NeueAachenProMedium.TTF);
  font-weight: normal;
  font-style: normal;
} */

body,
html {
  font-family: "Be Vietnam Pro", sans-serif; 
  overflow-x: hidden;
  color: #333333;
}
h1, h2, h3, h5, h6{
  font-family: "Archivo Narrow", sans-serif;
  font-style: italic;
}
a {
  text-decoration: none;
  color: inherit;
}

.highlights marquee p {
  display: inline-block; 
  margin: 0px 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: 100% auto;
  height: 105px;
}
.highlights img {
  width: 40px;
  position: relative;
}

.social_media {
  width: max-content;
}

.highlights marquee {
  width: 90%;
}
ul.dropdown-menu.fade-up.show li a:hover {
  color: #fe6a1d;
}
.highlights .social_media {
  background: var(--dark-gradient);
  padding: 4px 0px;
  width: 12%;
  text-align: center;
}
.campuslogo {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
}

.highlights {
  color: #fff;
  background: #333333;
  width: 100%;
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.highlights marquee p{
  font-size: 15px;
}
.highlights i {
  color: #fff;
  margin: 8px;
}
.icon-announce {
  position: absolute;
  top: 6px;
  left: 5px;
  z-index: 9;
}
.icon-announce:before{
  content: "";
  width: 130%;
  height: 140%;
  position: absolute;
  left: -10px;
  top: -10px;
  background: var(--dark-gradient);
  transform: rotate(7deg);
}
.highlights p {
  margin-bottom: 0;
}

.logo {
  width: 30%;
  padding: 6px;
}

nav.navbar { 
  padding: 0;
  width: 100%;
  border-radius: 30px 100px 30px 100px;
  /* float: left; */
  margin-top: 11px;
  position: relative;
  z-index: 9;
  margin: auto;
  display: block;
}
.header-nav {
  margin-bottom: -40px;
}

.pos-re {
  position: relative;
}
nav.navbar ul.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
nav.navbar ul.navbar-nav >li > div,nav.navbar ul.navbar-nav >li > a{
  position: relative;
}
nav.navbar ul.navbar-nav a.dropdown-item.active:before {
  /* transform: unset; */ 
  background: var(--dark-gradient);
  left: unset;
  right: 1px;
  width: 105%;
}
nav.navbar ul.navbar-nav a span{
  position: relative;
  color: #fff;
} 

nav.navbar ul.navbar-nav li a {
  font-weight: 400;
  background: transparent;
  color: #fff; 
  text-transform: uppercase;
}
.nav-link {
  color: #fff;
  padding: 10px 0px;
  margin: 0px 30px;
  width: 100%;
}
.dropdown-item {
  color: #000 !important;
}
.nav-link:focus, .nav-link:hover {
  color: #fe761d;
}
nav.navbar ul.navbar-nav li {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 300; 
}
.navbar-nav li.nav-item{
  position: relative;
}
 .navbar-nav li.nav-item:before{
 
  content: "";
  width: 98%;
  height: 100%;
  background: #333333;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transform: skewX(336deg);
}
.button.bg-color {
  background: var(--dark-gradient);
  color: #fff;
}
.button.online-registration {
  background: var(--dark-gradient);
  color: #fff;
  text-transform: uppercase !important;
}

.button {
  border: 0px;
  /* box-shadow: inset 0 0 7px 0px #3388ed; */
  padding: 9px 5px;
  border-radius: 5px;
  text-transform: capitalize;
  min-width: 150px;
  text-align: center;

}
.button.online-registration {
  background: linear-gradient(0deg, #5c96fb, #196beb);
  box-shadow: inset 0 0 3px 3px #035bc5;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 9px 15px;
}

.aboutmidsec.padded-sec {
  display: flex;
  align-items: center;
}
.aboutmidsec .button{
  margin: 5px;
  text-transform: capitalize;
}
.button.transparent-bg {
  border: 1px solid;
  min-width: 120px;
  text-align: center;
  font-weight: 600;
}
a:hover {
  color: unset;
}
.slider-sec {
  position: relative;
}
.events.padded-sec {
  padding: 0 100px;
}
.eventbg {
  width: 95%;
}
.button p{
  margin-bottom: 0;
}
.overBox {
  position: absolute;
  bottom: 8%;
  left: 7%;
  width: 400px;
  padding: 15px 20px 0;
  border: 1px solid #fff;
  border-radius: 14px;
  overflow: hidden;
}
.overBox-right{ 
  bottom: 8%;
  right: 7%;
  left: unset;
  width: auto;
}
.overBox-right img{
  margin-right: 15px;
  width: 30px;
  filter: invert();
}
.overBox-right a {
  display: block;
  position: relative;
  color: #fff;
  font-weight: 200;
  margin-bottom: 10px;
}
.overBox::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  filter: blur(1px);
  background: #101010d6;
}

.overBox h2 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 36px;
  color: #fff;
}
.overBox p {
  color: #fff;
}
.overBox p span{
  color:  #fff;
}
.overBox h2,
.overBox p,
.overBox button {
  position: relative; 
} 




.homeeventboximg p {
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0;
}

.homeeventboximg p img {
  width: 35px !important;
  height: auto !important;
}

.homeeventboximg {
  position: relative;
}

.homeeventboximg p span {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 90%;
  font-size: 12px;
  width: max-content;
  padding: 0px 10px;
  border-radius: 21px;
}

.homeeventbox {
  width: 95%;
  border-radius: 15px;
  overflow: hidden;
  background: #EEEEEE;
  border: 1px solid #FE621D;
  margin: 40px 0;
  height: 390px;
}
.title h3 {
  font-size: 34px;
  color: #000;
  font-style: italic;
  text-transform: uppercase;
} 
.homeeventbox .homeeventboxdesc {
  text-align: center;
  padding: 10px;
}

.homeeventboximg .lazyload-wrapper  img {
  height: 190px;
  width: 100%;
  object-fit: cover;
}

.homeeventboxtitle h3 {
  text-transform: uppercase;
  font-size: 19px;
  min-height: 46px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.homeeventboxtitle {
  margin-top: 10px;
}

.homeeventboxtitle p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  min-height: 70px;
}

.homeeventboxbottom {
  background: var(--dark-gradient);
  border-radius: 15px;
  color: #fff;
  padding: 5px;
  text-transform: uppercase;
  font-size: 13px;
}
button.dark-button {
  background: var(--dark-gradient);
  color: #fff;
  text-transform: uppercase; 
  border: 0px;
  border-radius: 18px;
  padding: 5px 14px
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000b0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}

.homeeventbox:hover .overlay {
  opacity: 1;
}
.slider-sec {
  position: relative;
}

.facilities .d-flex {
  align-items: center;
  justify-content: center;
}
.facilities .d-flex .faci_blk > div p {
  margin-bottom: 0;
}

.facilities .d-flex .faci_blk > div { 
  position: relative;
  width: 150px;
  height: 130px;  
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/tab-frame.png);
  background-size: 93% 93%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  background-position: center;
  border-radius: 9px;
  background-repeat: no-repeat;
}
.faci_blk{
  margin: 20px;
  position: relative;
  padding-bottom: 10px;
  margin-top: -40px;
}
.faci_blk:before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5px;
  left: 0;
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/rec-shadow.png);
  background-size: 100% 100%;
}
.facilities .d-flex .faci_blk img{ margin-bottom: 20px;}
.about p:before{
  content: "";
  width: 2px ;
  height: 100%;
  left: -20px;
  top: 0;
  position: absolute;
  background: #999999;
}

.about p{
  position: relative;
}

.about p span{
  color: #000000;
}
button.button {
  background: var(--dark-gradient);
  position: relative;
  overflow: hidden;
  color: #fff;
  text-align: left;
  padding: 6px 17px;
  min-width: 164px;
  font-size: 16px;
}
button.button span{position: relative;}
button.button:before{ 
  content: "";
  width: 119px;
  height: 155px;
  left: 0px;
  top: -41px;
  position: absolute;
  background: #333333;
  transform: rotate(102deg);
  border: 5px solid #fff;
}
.slick-list{
  overflow: hidden;
}
.slick-track{
  display: flex;
  min-width: 100% !important;
}

button.button i {
  float: right;
  position: relative;
  z-index: 9;
  margin-right: 0;  
  transition: 0.5s;
}
.shape_faci .d-flex > div:hover {
  filter: invert(2);
}
.shape_faci .d-flex > div{
  transition: 0.5s;
  cursor: pointer
}

.shape_faci .d-flex > div p {
  margin-bottom: 0;
  margin-top: 6px;
}
button.button:hover i {
  animation: 0.5s animatebtn infinite alternate;
}
@keyframes animatebtn{
  0%{
    margin-right: 0;
  }
  100%{
    margin-right: 5px;
  }
}
button.button:after{
content: "";
width: 80px;
height: 78px;
right: -30px;
top: -10px;
position: absolute;
background: var(--dark-gradient);
transform:
rotate(102deg);
border: 5px solid #fff;
}
.message-container{
  margin-top: 20px;
}
.messgae-title h3{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-transform: uppercase;
}
.messgae-title h3 img{
  width: 36px;
  margin-left: 10px;
}
.message-blk {
  padding: 30px;
  text-align: center;
  border: 1px solid #FE621D;
  border-radius: 8px;
  position: relative;
  width: 98%;
  margin: auto;
  background: #fff;
}
.messagebox {
  text-align: center;
}
.message_section{
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/message_bg.png);
  background-size: 100% 100%; 
  padding: 80px 0;
}

.messagebox .testimonial img {
  border-radius: 12px;
  border: 4px solid #fff;
  width: 205px;
}
p.testimonial-message {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.carousel-indicators{
  bottom: 40px
}
 .message-shape{
  width: 329px;
  margin-top: -2px;
 }
.slider-sec .carousel-indicators [data-bs-target] {
  background-color: #fff;
  height: 5px;
  opacity: 1
}

.slider-sec .carousel-indicators .active {
  background-color: #fe6c1d;
}
.messagedesc .messageboximg {
  background: var(--dark-gradient);
  width: max-content;
  margin: auto;
  width: 329px;
  padding: 47px 0 1px;
  margin-top: -40px;
  border-radius: 20px 20px 0 0;
}

.messagedesc .messageboximg p {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 8px;
}

.messagedesc .messageboximg p span {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
}
.message-blk:before{
  content: "";
  width: 50px;
  height: 60px;
  position: absolute;
  left: -13px;
  top: -10px;
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/quotes.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.message-blk:after{
  content: "";
  width: 50px;
  height: 60px;
  position: absolute;
  right: -13px;
  bottom: -10px;
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/quotes.png);
  background-size: 100%;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
.homegallery { 
  padding: 80px 0 100px;
  background: linear-gradient(0deg, #f8e3cd, transparent);
  position: relative;
}
.homegallery:before{
  content: "";
  width: 300px;
  height: 300px;
  right: 0;
  top: -10%;
  position: absolute;
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/galler-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.galleryimgbox{
  background: var(--dark-gradient);
  border-radius: 46px;
  width: 91%;
  padding: 3px;
  overflow: hidden;
  margin: auto;
  transition: 0.5s;
  margin-top: 0;
  position: relative;
}
.galleryimgbox:hover span {
  opacity: 1;
}
.galleryimgbox img.img-fluid {
    width: 100%;
}

.galleryimg {
    text-align: center;
    margin: 50px 0;
}

.galleryimg p {
 opacity: 0;
 transition: 0.5s;
}

.galleryimg:hover p {opacity: 1}
.galleryimg:hover .galleryimgbox {width: 100%;
  margin-top: -20px;}

.galleryimg h5 {
    text-transform: uppercase;
    margin-top: 10px;
}
.homegallery .title.gallery-sec {
  display: flex;
  justify-content: center;
}

.homegallery button.button {
  float: right;
  position: absolute;
  right: 49px;
}
.branch-blk{
  padding: 40px 20px;
  border-radius: 10px;
  width: 90%;
  margin: auto;
  background: var(--dark-gradient);
}
.branch-blk h4, .branch-blk p b{
  width: 100%;
  display: block;
  text-align: center;
}
.branch-blk p {
  color: #fff;
}
.branch-blk button.button:after{
  background: #333333;
}
.branch-blk  button.button{
background: var(--dark-gradient);
position: relative;
overflow: hidden;
color: #fff;
text-align: left;
padding: 6px 11px;
min-width: 164px;
margin: auto;
display: block;
margin-top: 30px;
}
.branch-blk .owl-nav {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%); display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}
 
 

.branch-blk .owl-nav button {
  background: #333333 !important;
  padding: 0px 10px;
  color: #fff;
  min-width: 19px;
}
.branch-blk .owl-nav button span{color: #fff;font-size: 25px}
.owl-nav {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
}

.owl-nav button {}

.owl-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.owl-nav button {
  background: #333333 !important;
  padding: 0px 10px;
  color: #fff;
  min-width: 19px;
}
.owl-nav button span{color: #fff;font-size: 25px
                  }


.quick-link ul {
  padding: 0;
  list-style: none;
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.shape_faci{
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/shape-faci.png);
  width: 700px;
  height: 700px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleDiv {
  width: 500px;
  height: 500px;
  position: relative;
  display: block;
  margin-left: 60px;
}

.circleDiv img {
  width: 62px;
}

.abs-blk {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(white, #d1d1d1);
  padding: 9px;
  cursor: pointer;
}

.cir1 {
  left: 0%;
  top: 20%;
}
.cir2 {
  left: 42%;
  top: -7%;
}
.cir3 {
  right: 0%;
  top: 20%;
}
.cir4 {
  right: 0;
  top: 69%;
}
.cir5 {
  right: 42%;
  top: 93%;
}
.cir6 {
  left: 1%;
  top: 67%;
}

.circleDiv h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase; 
  font-size: 45px;
  letter-spacing: 6px;
}

.detail-blk {
  width: 320px;
  border-right: 2px solid;
  padding-right: 25px;
}

.abs-blk img.gif-form {
  display: none;
}

.circleDiv:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 7px solid #fe621d;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.facilities {
  float: left;
  width: 100%;
  z-index: 9;
  position: relative;
}

.aboutsec {
  background: linear-gradient(182deg, #e5f3f5, transparent);
  padding: 200px 0 80px;
}
.facilities_sec{
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/faci_bg.png);
  background-size: 100% 100%;
}
.facilities_sec .d-flex{
  align-items: center;
  
}
.facility_selection {
  display: none;
}

.facility_selection.active {
  display: block;
}
.abs-blk.active {
  transform: scale(1.5);
}

.abs-blk.active img.img-form {
  display: none;
}

.abs-blk.active img.gif-form {
  display: block;
}
.footer h3{
  margin-bottom: 20px;
}
.footer:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0px;
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/ftr-shape.png);  
  background-size: 100% 100%;
} 

.footer::before {
  content: "";
  width: 66%;
  height: 95%;
  position: absolute;
  left: -260px;
  top: 0;
  background: var(--dark-gradient);
  background-size: 100% 100%;
  transform: rotate(3deg);
  border-radius: 20%;
} 

.footer {
  position: relative;
  padding: 80px 0 20px; 
  margin-top: -40px;
}

.footer > div {
  position: relative;
  color: #fff;
  z-index: 9;
} 
.footer p i{margin-right: 5px;}
.footer p{
  font-weight: 200;
  display: flex;
  font-size: 15px;
}
.ftr-logo  {
  margin-left: 16px;
  display: block;
  width: max-content;
}

.ftr-logo .social-media i {
  margin: 10px;
}
.branch_carowsel {
  margin-top: -130px;
}

.ftr-logo .social-media {
  width: max-content;
  margin: 20px auto;
  display: block;
}

.branch-blk p {
    display: flex;
}

.branch-blk p i {
    margin-right: 10px;
}
.quick-link ul li { 
  margin-bottom: 10px;
  font-weight: 200;
  font-size: 14px;
}
.copyright {
  /* position: absolute; */
  width: 100%;
  text-align: center;
  background:linear-gradient(45deg, #333333, #333333, #fe691d, #333333, #333333);
  bottom: 0;
  z-index: 9;
  left: 0;
  color: #fff;
  padding: 5px;
  font-size: 14px;
}
.branch-blk button.button:after {
  content: "";
  width: 83px;
  height: 42px;
  right: -21px;
  top: -15px;
  position: absolute; 
  transform: rotate(102deg);
  border: 5px solid #fff;
  border-top: 2px solid #fe6b1d;
}
.shape_faci .d-flex {
  width: 50%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.shape_faci .d-flex > div {
  width: 50%;
  text-align: center;
  height: 172px;
  padding: 25px;
}
.branch-carowsel {
  margin-top: -120px;
  position: relative;
}
/* Inner Page */



.row.tabs-dynamic {
  background: #ebebeb;
  padding: 13px 0;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic input, .row.tabs-dynamic select {
  border: 1px solid #ddd;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
}


.eventbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 13px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;
}

.eventbox .eventboximg {
  width: 27%;
}

.eventbox .eventboxdesc {
  width: 70%;
}

.eventbox .eventboximg img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  background: #ebebeb;
}

.eventbox .eventdate {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.eventbox .eventdate i {
  margin-right: 5px;
  color: #2e949b;
}

.eventbox .eventattch {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--dark-gradient);
  color: #fff;
  padding: 2px 6px;
}
.evt-img > img {
  height: 180px;
  object-fit: contain;
  width: 100%;
  background: #e9e9e9;
}
.innerSection .news-blk {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.news-blk .news-head {
  width: 13%;
}

.news-blk .news-content {
  width: 84%;
}

.news-blk  .attachment {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--dark-gradient);
  color: #fff;
  padding: 3px 7px;
}
.float-md-start.msgimg {
  text-align: center;
  color: #fff;
}
.float-md-start.msgimg { 
  background: var(--dark-gradient);
  padding: 7px;
  border-radius: 15px;
  box-shadow: 2px 50px 14px -40px #484848;
  margin-right: 20px;
  width: 300px;
  margin-bottom: 30px;
}
.float-md-start.msgimg img{
  width: 100%;
}
.news-blk .news-head {
  text-align: center;
  background: var(--dark-gradient);
  color: #fff;
  padding: 27px 5px;
}

.news-blk .news-head span {
  display: block;
}

.news-blk .news-content h3 {
  font-size: 21px;
}

.news-blk .news-content p {
  margin-bottom: 0;
  font-size: 14px;
}

.news-blk .news-head h2 {
  font-size: 24px;
}

.gal_new {
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  position: relative;
}

.gal_new img.img-fluid {
  height: 210px;
  object-fit: contain;
  width: 100%;
  background: #dbdbdb;
}

.gal_new h4.catgry-name {
  font-size: 20px;
  margin-top: 20px;
} 

.gal_new .total-img {
  background: var(--dark-gradient);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  padding:  5px 10px;
  box-shadow: 7px 5px 11px -10px #000;
}

.gal_new .total-img p {
  margin-bottom: 0;
} 

.gal_new h6.date {
  font-size: 14px;
}

.gal_new h6.date span {
  float: right;
  font-size: 20px;
  cursor: pointer;
}
.sub_gallery img{
  height: 200px;
  background: #ddd;
  object-fit: contain;
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
} 

.discription {
    text-align: center;
    margin-top: 20px;
}

.discription h3.subhead {
    font-size: 22px;
    color: var(--dark-gradient);
}
.video-gallery a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.video-gallery a i {
  font-size: 30px;
  margin: 20px;
  color: red;
}

.video-gallery {
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 5px;
}

.video-gallery a h3 {
  font-size: 18px;
}

.video-gallery a p {
  margin-bottom: 0;
  font-size: 13px;
}

.popup {
  padding-right: 0 !important;
} 
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:40%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #FE621D; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.popup .carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.popup .carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.popup .carousel-item .modalcard .card-body { padding: 10px }
.popup .carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.popup .carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.popup .carousel-item button.btn-close:focus { box-shadow: none }
.popup .carousel-item a.btn.btn-primary.btn-sm { border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a; 
  transition: .5s; background: var(--dark-gradient); border: #FE621D; margin: 0 10px; color: #fff!important ;     padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;}
  .popup .carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #FE621D; }
  .popup .carousel-item a.btn.btn-primary.btn-sm:hover {
  background:  var(--dark-gradient);
}
.button.online-registration a {
  display: flex;
  justify-content: center;
}
.galleryimgbox img.img-fluid {
  height: 300px;
  object-fit: contain;
  background: #ddd;
  border-radius: 46px;
}
.popup .carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #FE621D;right: 0;}
.popup .carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/TAGOREBG/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.popup .modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
.breadcrumb {
  min-height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/breadcrumb1.jpg);
  background-size: 100% auto;
  background-position: top;
  position: relative;
}
.breadcrumb ul {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  list-style: none;
  padding-left: 0;
  padding: 7px 10px;
  position: relative;
  border-radius: 5px 5px 0px 0px;
}

.breadcrumb ul li {
  padding: 0 11px;
  border-right: 1px solid  #c9c9c9;
}

.breadcrumb ul li:last-child {
  border-right: 0;
}

.breadcrumb:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, black, #00000059);
  position: absolute;
  left: 0;
  top: 0;
}
.innerSection {
  padding: 40px 0 100px;
}

.innerSection .title {
  text-align: center;
  margin-bottom: 30px;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.contact-container .contact-blk {
  margin: 25px;
  margin-top: -30px;
  background: #fff;
  padding: 9px;
  border-radius: 5px;
  box-shadow: 0px -17px 14px -20px #000, 0px 17px 14px -20px #000;
  width: 25%;
  text-align: center;
  border: 1px solid #ddd;
}

.contact-container .contact-blk img {
  width: 50px;
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

iframe {
  border: 5px solid #e3e3e3;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.contact-container .contact-info h6.title {
  margin-bottom: 4px;
  font-size: 22px;
  color: #fe951d;
}
.table-responsive th {
  min-width: 170px;
}

.table-responsive table {
  font-size: 14px;
}

.table-responsive table th {
  background: var(--dark-gradient);
  color: #fff;
  font-weight: 400;
}

.table-responsive {
  margin-bottom: 20px;
}

.table-responsive table a {
  text-decoration: none;
}
.activities_blk img {
  width: 100%;
}

.activities_blk {
  box-shadow: 0px 0px 12px 0px #00000038;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 15px;
}
.activities_blk img{border-radius: 15px;}
.activities_blk h3 {
  text-align: center;
  margin-top: 20px;
  color: #fe621d;
  text-transform: uppercase;
}
.innerSection ul li{
  margin-bottom: 20px;
  list-style-type: none;
  position: relative;
  padding-left: 40px;
}
.innerSection ul li:before{
  content: "";
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/TAGOREBG/public/Images/go-right.png);
  background-size: 100%;
}
.galleryimg .gallerydesc p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.gal-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.gal-content h4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.button.light.padding {
  background: #0e0606;
  width: max-content;
  margin-bottom: 15px;
  color: #fff;
}
.galleryimgbox span {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  background: #fe991d;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 13px;
  transition: all 0.5s ease-in;
}
.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
} .tc {
  width: 49%;
  margin-bottom: 30px;
} .tcblock iframe {
  width: 49%;
  border: 0px solid #fff;
    box-shadow: 0px 0px 0px #ddd;
} .tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
} .tc label p {
  margin-bottom: 0px;
} .tc .form-group {
  margin-bottom: 20px;
} .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
} a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
} .viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background:linear-gradient(0deg, #FE621D, #FEA01D);
  transition: all 0.2s ease-in;
} .viewdetailsbtn:hover {
  background:linear-gradient(0deg, #FEA01D, #FE621D)
}
.cm {
  width: 50%;
}
.campuslogo {
  position: fixed;
  right: 0px;
  bottom: 10px;
  z-index: 99;
}
.campuslogo img {
  width: 100px;
}
@media(max-width: 1600px) {
  .events.padded-sec {
    padding: 0 20px;
}
.circleDiv{
  width: 400px;
  height: 400px;
}
.shape_faci {
  width: 650px;
  height: 680px;}
}

#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s; }
  div#lightboxContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background: #000000d1;
    padding-top: 2%;
}

.imageModal > div {
    right: 0;
    margin-left: auto;
    margin-right: 0;
    position: absolute;
    z-index: 99
}
#myImg:hover {
  opacity: 0.7; }

.erp_sec p {
  line-height: 40px !important;
  margin-bottom: 0;
  font-size: 16px;
}
h4.imgheading {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
}

@media(max-width: 1400px) {
  .button p {
    margin-bottom: 0;
    font-size: 14px;
}
.button.transparent-bg, .button{
  min-width: 100px;
}
  .shape_faci {
    width: 560px;
    height: 580px;
    background-size: 109% 111%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
}
.circleDiv {
  width: 350px;
  height: 350px;
}
.circleDiv img {
  width: 49px;
}
.circleDiv h2 { 
  font-size: 32px; 
}
.shape_faci .d-flex > div {
  width: 45%;
  text-align: center;
  height: 167px;
  padding: 19px;
}
.shape_faci .d-flex {
  width: 64%;}
  nav.navbar ul.navbar-nav li {
    font-size: 14px;
  }
  .nav-link { 
    margin: 0px 20px;
  }
  nav.navbar ul.navbar-nav li a { 
    padding: 10px 20px; 
}
  .title h2 {
    font-size: 25px;
  }
  .branch-blk{
    padding: 30px 13px;
  }
  .footer p{
    font-size: 14px;
  }
}

@media(max-width: 1200px) {
  nav.navbar ul.navbar-nav li {
    font-size: 10px;
  }
  nav.navbar ul.navbar-nav li a { 
    padding: 10px 8px; 
}
  .highlights .social_media{
    width: 15%;
  }
  .message_section{
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
  }
  .branch_carowsel{margin-top: 0;}
  .header .logo {
    width: 100%;
  }

  .header {
    flex-wrap: wrap;
    height: auto;
  }

  .header .logo img {
    margin: auto;
    display: block;
  } 
  nav.navbar {
    width: 100%;
    margin: 0;
  }
}

@media(max-width: 991px) {
  .nav-link {
    color: #000;
  }
  nav.navbar ul.navbar-nav li {
    font-size: 14px;
}
nav.navbar ul.navbar-nav li a{
  color: #000;
}
.header-nav {
  margin-bottom:  0px;
}
  .tc {
    width:100%;
  }
  .tcblock iframe {
    width: 100%;
}

  .aboutmidsec.padded-sec {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.highlights .social_media{
  width: 20%;
}
  .shape_faci{
    margin: auto;
  }
  .about p:before{
    display: none;
  }
  .about{
    text-align:  center;
  }

.about-img img {
  margin: auto;
  display: block;
}
.overBox{
  display: none;
}
  nav.navbar {
    background: transparent;
  }

  div#main_nav {
    background: #fff;
    text-align: left;
  }

  div#main_nav ul {
    align-items: flex-start;
    padding: 15px;
    position: static;
    background: #fff;
    border-radius: 15px;
  }

  div#main_nav ul li {
    padding: 9px 0;
    border-bottom: 1px dashed #ddd;
    width: 100%;
  }
  button.navbar-toggler{
    background: var(--dark-gradient);
    position: absolute;
    top: -40px;
    right: 0;
    vertical-align: middle;
    color: #fff;
    padding-bottom: 0;
  }
  nav.navbar ul.navbar-nav>li> div > a:before{
    display: none;
  }
  nav.navbar ul.navbar-nav a span{
    color: #000;
  }
  nav.navbar ul.navbar-nav li a, nav.navbar ul.navbar-nav li a.active{
    padding: 10px 0;
  }
  .header-nav {
    z-index: 99;
    width: 100%;
}
}

@media(max-width: 767px) {
  .title.messgae-title h3 {
    display: flex;
}
  .highlights .social_media{
    display: none;
  }
  .footer:after{
    background: var(--dark-gradient);
  }
  .homegallery button.button {
    position: relative;
    right: auto;
    float: unset;
}

.title h3 {
    display: block;
}

.homegallery .title.gallery-sec {
    flex-direction: column;
    align-items: center;
}
  .events.padded-sec {
    padding: 40px 20px;
}
.facilities .d-flex {
  flex-wrap: wrap;
}
.facilities .d-flex .faci_blk > div{
  width: 220px;
  height: 220px;
  margin-bottom: 40px;
}
.faci_blk:before{
  height: 88%;
}
.facilities_sec .d-flex {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 40px 0
} 
}

@media(max-width: 600px) {
  .shape_faci{
    width: 320px;
    height: 400px;
    background-size: 129% 122%;
  }


.facilities_sec .d-flex  .detail-blk {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}
  .shape_faci .d-flex > div {
    width: 49%;
    text-align: center;
    height: 126px;
    padding: 7px;
    font-size: 13px;
}
}

@media(max-width: 400px) {
  .aboutmidsec .button{
    margin: 2px;
  }
}